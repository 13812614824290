<template>
  <div class="w-full p-4 flex flex-col">
    <section class="flex flex-col w-full mt-4 mb-8">
      <h2 class="text-3xl font-bold mb-4">伝えたいことリスト</h2>
      <div class="flex flex-col">
        <div v-if="todos.length === 0" class="mt-4">
          <p v-if="!loading">TODOがありません。</p>
          <p v-else>TODO取得中...</p>
        </div>
        <div v-else>
          <div
            v-for="(todo, i) in todos"
            :key="i"
            class="px-3 py-2 flex flex-col bg-white rounded-md mb-4"
          >
            <div class="flex flex-row items-center mb-2">
              <input
                v-if="!todo.isDone"
                :id="`checkbox-${i}`"
                class="mr-2 w-4 h-4"
                type="checkbox"
                :checked="todo.isDone"
                @change="openUpdateModal(todo, i, $event.target.checked)"
              />
              <div v-else class="mr-2 w-5 h-5 cursor-pointer">
                <img
                  class="w-full"
                  :src="
                    require(`../assets/todo-reflection/${todo.reflection ??
                      'good'}.svg`)
                  "
                  :alt="todo.reflection ?? 'good'"
                  :class="
                    todo.reflection
                      ? `todo-reflection-${todo.reflection}`
                      : 'todo-reflection-good'
                  "
                  @click="openUpdateModal(todo, i)"
                />
              </div>
              <p class="flex-1 font-medium text-lg sm:text-xl">
                {{ todo.title }}
              </p>
            </div>
            <p class="mb-2">
              {{ `作成 : ${todo.createdAt}` }}
            </p>
            <p>
              チェック :
              <span
                :class="{
                  'text-red-500': !todo.isDone,
                  'text-green': todo.isDone
                }"
                >{{ todo.isDoneAt }}</span
              >
            </p>
          </div>
          <div
            v-if="!todosFinished"
            class="flex flex-shrink-0 justify-center py-4"
          >
            <div
              class="px-4 py-2 rounded-md text-gray-700 text-sm underline transition-colors duration-300 cursor-pointer hover:bg-gray-100"
              @click="getMoreTodos"
            >
              <span v-if="!moreTodosLoading">さらに読み込む</span>
              <span v-if="moreTodosLoading">読み込み中...</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <MUpdateTodoModal
    v-if="isModalOpen"
    :selected-todo="selectedTodo"
    :student="student"
    @close="closeModal"
  ></MUpdateTodoModal>
</template>

<script lang="ts">
import MSInfoItem from "@/components/setting/MSInfoItem.vue";
import MUpdateTodoModal from "@/components/MUpdateTodoModal.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { awaitStudentLoaded } from "@/store/index";
import { Student } from "@/entities/student";
import { todoCollectionKey, convertToTodo, Todo } from "@/entities/todo";
import dayjs from "dayjs";
import "dayjs/locale/ja";

type TodoData = {
  id: string;
  title: string;
  createdAt: string;
  isDone: boolean;
  isDoneAt: string;
  reflection: string;
};

const limit = 20;

@Options({
  components: {
    MSInfoItem,
    MUpdateTodoModal
  }
})
export default class Todos extends Vue {
  student: Student | null = null;
  todos: TodoData[] = [];
  selectedTodo: { [key: string]: string | number | boolean } | null = null;
  loading = false;
  todosFinished = false;
  moreTodosLoading = false;
  isModalOpen = false;

  formatTodos(todos: Todo[]) {
    if (todos.length === 0) return [];
    return todos
      .filter(_ => _)
      .map(todo => {
        return {
          id: todo.ref.id,
          title: todo.data.title,
          createdAt: dayjs.unix(todo.data.createdAt).format("YYYY-MM-DD"),
          isDone: todo.data.isDone,
          isDoneAt:
            todo.data.isDoneAt !== 0
              ? dayjs.unix(todo.data.isDoneAt as number).format("YYYY-MM-DD")
              : "未実行",
          reflection: todo.data.reflection ? todo.data.reflection : "good" // 万が一データがない場合はデフォルトで「good」を適応する
        };
      });
  }

  async getMoreTodos() {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      return;
    }
    this.moreTodosLoading = true;
    try {
      const id = this.todos[this.todos.length - 1].id;
      const ref = this.student.ref;
      const offsetSnapshot = await ref
        .collection(todoCollectionKey)
        .doc(id)
        .get();
      const snapshot = await ref
        .collection(todoCollectionKey)
        .orderBy("createdAt", "desc")
        .orderBy("title")
        .startAfter(offsetSnapshot)
        .limit(limit)
        .get();
      const inComingTodos = !snapshot.empty
        ? this.formatTodos(
            snapshot.docs.map(doc => convertToTodo(doc.data(), doc.ref))
          )
        : [];
      if (inComingTodos.length < limit) {
        this.todosFinished = true;
      }

      this.todos = [...this.todos, ...inComingTodos];
    } catch (e) {
      alert("TODOの取得に失敗しました。");
      console.error(e);
    } finally {
      this.moreTodosLoading = false;
    }
  }

  async openUpdateModal(todo: TodoData, index: number, checked?: boolean) {
    if (!todo || !this.student) {
      alert("TODOを更新できません。");
      return;
    }

    // 「checkedがundefined」 = 「振り返り済のものを編集している挙動」、と想定する
    if (checked !== undefined && checked === false) {
      alert("この操作はできません。");
      return;
    }
    if (checked) {
      // 始めてチェックする時は、チェックが入ったこを見せるためにあえて少し間をあける
      await new Promise(_ => setTimeout(_, 100));
    }
    this.selectedTodo = {
      ...todo,
      index,
      editing: checked === undefined
    };
    this.isModalOpen = true;
  }

  closeModal(index: number | null) {
    this.isModalOpen = false;
    if (index === null || index === undefined) return;
    const targetCheckbox = document.getElementById(
      `checkbox-${index}`
    ) as HTMLInputElement;
    if (!targetCheckbox) return;
    targetCheckbox.checked = false;
  }

  async created() {
    this.loading = true;
    this.student = (await awaitStudentLoaded(store)) as Student;
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.loading = false;
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "TODOを取得中...");

      const todoSnapShot = await this.student.ref
        .collection(todoCollectionKey)
        .orderBy("createdAt", "desc")
        .orderBy("title")
        .limit(limit)
        .get();

      if (!todoSnapShot || todoSnapShot.empty) {
        this.todosFinished = true;
        return;
      }

      this.todos = this.formatTodos(
        todoSnapShot.docs.map(doc => convertToTodo(doc.data(), doc.ref))
      );

      if (this.todos.length < limit) {
        this.todosFinished = true;
      }
    } catch (e) {
      alert("TODOの取得に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.loading = false;
    }
  }
}
</script>

<style scoped>
.text-green {
  color: rgb(25, 165, 120);
}
</style>
