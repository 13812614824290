
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import store from "@/store";
import { Student } from "@/entities/student";
import { todoCollectionKey } from "@/entities/todo";

@Options({
  components: {
    MBaseModal,
    MButton
  },
  emits: ["close", "select"],
  props: { selectedTodo: Object, student: Object }
})
export default class MUpdateTodoModal extends Vue {
  student: Student | null = null;
  reflectionsItems = [
    { title: "best", text: "良くできた" },
    { title: "good", text: "普通" },
    { title: "bad", text: "イマイチ" }
  ];

  selectedTodo: { [key: string]: string | number | boolean } | null = null;

  get editing(): boolean {
    return (this.selectedTodo?.editing as boolean) ?? false;
  }

  select(roomId: string) {
    this.$emit("select", roomId);
  }

  close() {
    this.$emit(
      "close",
      this.selectedTodo?.index !== null ? this.selectedTodo?.index : null
    );
  }

  async revertToUndone() {
    if (!this.editing) return;
    if (!this.student || !this.selectedTodo) {
      alert("TODOを未実行に更新てきません。");
      return;
    }
    if (
      !window.confirm(
        "今記録されたいる実行日も削除されます。このTODOを未実行に戻して良いですか？"
      )
    )
      return;
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "TODOを未実行に更新中...");
      await this.student.ref
        .collection(todoCollectionKey)
        .doc(this.selectedTodo.id as string)
        .update({
          isDone: false,
          isDoneAt: 0,
          reflection: null
        });
    } catch (e) {
      alert("TODOを未実行に戻すことができません。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    }
  }

  async updateTodoStatus(title: string) {
    if (!this.student || !this.selectedTodo) {
      alert("TODOを更新できません。");
      return;
    }

    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "TODOを更新中...");
      if (this.editing) {
        // もともと振り返り済だったものを編集する時の挙動
        await this.student.ref
          .collection(todoCollectionKey)
          .doc(this.selectedTodo.id as string)
          .update({
            reflection: title
          });
      } else {
        // はじめてチェックをつけて、振り返りをする時の挙動
        await this.student.ref
          .collection(todoCollectionKey)
          .doc(this.selectedTodo.id as string)
          .update({
            isDone: true,
            isDoneAt: Math.floor(Date.now() / 1000),
            reflection: title
          });
      }
    } catch (e) {
      alert("TODOの更新に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    }
  }

  created() {
    if (!this.student || !this.selectedTodo) {
      this.close();
      return;
    }
  }
}
