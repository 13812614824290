
import MSInfoItem from "@/components/setting/MSInfoItem.vue";
import MUpdateTodoModal from "@/components/MUpdateTodoModal.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { awaitStudentLoaded } from "@/store/index";
import { Student } from "@/entities/student";
import { todoCollectionKey, convertToTodo, Todo } from "@/entities/todo";
import dayjs from "dayjs";
import "dayjs/locale/ja";

type TodoData = {
  id: string;
  title: string;
  createdAt: string;
  isDone: boolean;
  isDoneAt: string;
  reflection: string;
};

const limit = 20;

@Options({
  components: {
    MSInfoItem,
    MUpdateTodoModal
  }
})
export default class Todos extends Vue {
  student: Student | null = null;
  todos: TodoData[] = [];
  selectedTodo: { [key: string]: string | number | boolean } | null = null;
  loading = false;
  todosFinished = false;
  moreTodosLoading = false;
  isModalOpen = false;

  formatTodos(todos: Todo[]) {
    if (todos.length === 0) return [];
    return todos
      .filter(_ => _)
      .map(todo => {
        return {
          id: todo.ref.id,
          title: todo.data.title,
          createdAt: dayjs.unix(todo.data.createdAt).format("YYYY-MM-DD"),
          isDone: todo.data.isDone,
          isDoneAt:
            todo.data.isDoneAt !== 0
              ? dayjs.unix(todo.data.isDoneAt as number).format("YYYY-MM-DD")
              : "未実行",
          reflection: todo.data.reflection ? todo.data.reflection : "good" // 万が一データがない場合はデフォルトで「good」を適応する
        };
      });
  }

  async getMoreTodos() {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      return;
    }
    this.moreTodosLoading = true;
    try {
      const id = this.todos[this.todos.length - 1].id;
      const ref = this.student.ref;
      const offsetSnapshot = await ref
        .collection(todoCollectionKey)
        .doc(id)
        .get();
      const snapshot = await ref
        .collection(todoCollectionKey)
        .orderBy("createdAt", "desc")
        .orderBy("title")
        .startAfter(offsetSnapshot)
        .limit(limit)
        .get();
      const inComingTodos = !snapshot.empty
        ? this.formatTodos(
            snapshot.docs.map(doc => convertToTodo(doc.data(), doc.ref))
          )
        : [];
      if (inComingTodos.length < limit) {
        this.todosFinished = true;
      }

      this.todos = [...this.todos, ...inComingTodos];
    } catch (e) {
      alert("TODOの取得に失敗しました。");
      console.error(e);
    } finally {
      this.moreTodosLoading = false;
    }
  }

  async openUpdateModal(todo: TodoData, index: number, checked?: boolean) {
    if (!todo || !this.student) {
      alert("TODOを更新できません。");
      return;
    }

    // 「checkedがundefined」 = 「振り返り済のものを編集している挙動」、と想定する
    if (checked !== undefined && checked === false) {
      alert("この操作はできません。");
      return;
    }
    if (checked) {
      // 始めてチェックする時は、チェックが入ったこを見せるためにあえて少し間をあける
      await new Promise(_ => setTimeout(_, 100));
    }
    this.selectedTodo = {
      ...todo,
      index,
      editing: checked === undefined
    };
    this.isModalOpen = true;
  }

  closeModal(index: number | null) {
    this.isModalOpen = false;
    if (index === null || index === undefined) return;
    const targetCheckbox = document.getElementById(
      `checkbox-${index}`
    ) as HTMLInputElement;
    if (!targetCheckbox) return;
    targetCheckbox.checked = false;
  }

  async created() {
    this.loading = true;
    this.student = (await awaitStudentLoaded(store)) as Student;
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.loading = false;
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "TODOを取得中...");

      const todoSnapShot = await this.student.ref
        .collection(todoCollectionKey)
        .orderBy("createdAt", "desc")
        .orderBy("title")
        .limit(limit)
        .get();

      if (!todoSnapShot || todoSnapShot.empty) {
        this.todosFinished = true;
        return;
      }

      this.todos = this.formatTodos(
        todoSnapShot.docs.map(doc => convertToTodo(doc.data(), doc.ref))
      );

      if (this.todos.length < limit) {
        this.todosFinished = true;
      }
    } catch (e) {
      alert("TODOの取得に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.loading = false;
    }
  }
}
