<template>
  <MBaseModal @close="close">
    <template #title>TODOを更新</template>
    <template #body>
      <div class="w-full flex flex-col items-center text-center">
        <p class="text-base sm:text-lg mb-4 sm:mb-8">
          相手に伝えた結果を記録しましょう。
        </p>
        <div
          v-for="(item, i) in reflectionsItems"
          :key="i"
          class="w-full sm:w-96 flex flex-row items-center bg-reflection-item rounded-lg p-4 sm:pl-8 mb-4 cursor-pointer"
          @click="updateTodoStatus(item.title)"
        >
          <div class="w-16 sm:w-18" :class="`todo-reflection-${item.title}`">
            <img
              class="w-full"
              :src="require(`../assets/todo-reflection/${item.title}.svg`)"
              :alt="item.title"
            />
          </div>
          <h2 class="flex-1 text-center text-2xl sm:text-2xl">
            {{ item.text }}
          </h2>
        </div>
      </div>
    </template>
    <template #footer>
      <MButton negative :invalid="!editing" @click="revertToUndone"
        >未実行に戻す</MButton
      >
    </template>
  </MBaseModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import store from "@/store";
import { Student } from "@/entities/student";
import { todoCollectionKey } from "@/entities/todo";

@Options({
  components: {
    MBaseModal,
    MButton
  },
  emits: ["close", "select"],
  props: { selectedTodo: Object, student: Object }
})
export default class MUpdateTodoModal extends Vue {
  student: Student | null = null;
  reflectionsItems = [
    { title: "best", text: "良くできた" },
    { title: "good", text: "普通" },
    { title: "bad", text: "イマイチ" }
  ];

  selectedTodo: { [key: string]: string | number | boolean } | null = null;

  get editing(): boolean {
    return (this.selectedTodo?.editing as boolean) ?? false;
  }

  select(roomId: string) {
    this.$emit("select", roomId);
  }

  close() {
    this.$emit(
      "close",
      this.selectedTodo?.index !== null ? this.selectedTodo?.index : null
    );
  }

  async revertToUndone() {
    if (!this.editing) return;
    if (!this.student || !this.selectedTodo) {
      alert("TODOを未実行に更新てきません。");
      return;
    }
    if (
      !window.confirm(
        "今記録されたいる実行日も削除されます。このTODOを未実行に戻して良いですか？"
      )
    )
      return;
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "TODOを未実行に更新中...");
      await this.student.ref
        .collection(todoCollectionKey)
        .doc(this.selectedTodo.id as string)
        .update({
          isDone: false,
          isDoneAt: 0,
          reflection: null
        });
    } catch (e) {
      alert("TODOを未実行に戻すことができません。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    }
  }

  async updateTodoStatus(title: string) {
    if (!this.student || !this.selectedTodo) {
      alert("TODOを更新できません。");
      return;
    }

    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "TODOを更新中...");
      if (this.editing) {
        // もともと振り返り済だったものを編集する時の挙動
        await this.student.ref
          .collection(todoCollectionKey)
          .doc(this.selectedTodo.id as string)
          .update({
            reflection: title
          });
      } else {
        // はじめてチェックをつけて、振り返りをする時の挙動
        await this.student.ref
          .collection(todoCollectionKey)
          .doc(this.selectedTodo.id as string)
          .update({
            isDone: true,
            isDoneAt: Math.floor(Date.now() / 1000),
            reflection: title
          });
      }
    } catch (e) {
      alert("TODOの更新に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    }
  }

  created() {
    if (!this.student || !this.selectedTodo) {
      this.close();
      return;
    }
  }
}
</script>

<style lang="scss" scoped>
.bg-reflection-item {
  background-color: rgb(255, 255, 230);
  transition: background-color 0.2s;

  &:hover {
    background-color: rgb(255, 255, 200);
  }
}
</style>
